'use client' // Error components must be Client Components

import { useEffect } from 'react'

import { InternalError } from '@/components/internal-error'
import { consoleError } from '@/common/utils/console'

const Error = ({
  error,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) => {
  useEffect(() => {
    consoleError('error.tsx', error)
  }, [error])

  return <InternalError />
}
export default Error
